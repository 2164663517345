<template>
  <div class="outDiv">
    <div class="info-input animated fadeInUp">
      <div class="info-top">
        <div class="per-class">
          <span>选择品种分类</span>
          <!-- productCurrent_page -->
          <el-select
            class="width170"
            v-model="cropClass"
            placeholder="请选择品种分类"
            @change="changeCropClass(1, $event)"
            v-loadMore="loadMoreClassData"
          >
            <el-option
              :label="item.name"
              :value="item.id"
              v-for="item in cropClassList"
              :key="item.id"
            ></el-option>
          </el-select>
          <span>选择产品</span>

          <el-select
            v-model="crop"
            placeholder="请选择产品"
            @change="changeCropItems"
            v-loadMore="loadMoreItemsData"
          >
            <el-option
              :label="item.title"
              :value="item.id"
              v-for="item in cropItemsList"
              :key="item.id"
            ></el-option>
          </el-select>
          <span>选择流程</span>

          <el-select
            v-model="flowId"
            placeholder="请选择流程"
            @change="changeFlow"
          >
            <el-option
              :label="item.title"
              :value="item.id"
              v-for="item in flowList"
              :key="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="info-main">
        <div class="forms">
          <div class="form-title">
            <img src="../../assets/image/traceability/icon7.png" />
            <span>填写信息</span>
          </div>
          <el-form
            :model="form"
            ref="form"
            class="complete-form form-item soll-form"
          >
            <div class="subtitle">基础信息</div>
            <el-form-item
              label="地块"
              :label-width="formLabelWidth"
              prop="land"
            >
              <el-select
                class="ohter-width"
                v-model.number="form.land"
                placeholder="请选择地块"
                :class="isFocusShow ? 'active' : ''"
                @focus="getFocus"
                @blur="getBlur"
                v-loadMore="loadMoreLandData"
              >
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="item in landList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="农资"
              :label-width="formLabelWidth"
              prop="capital"
            >
              <MyChangeMeans
                ref="changeMean"
                :isAddBtnShow="true"
                @getMeansList="getMeansList"
              ></MyChangeMeans>
            </el-form-item>

            <div class="subtitle">拓展信息</div>
            <el-form-item
              :label="t"
              :label-width="formLabelWidth"
              v-for="(t, i) in tagArr"
              :key="i"
            >
              <!-- :value="i" -->
              <el-input
                :value="tagArrVal[i]"
                autocomplete="off"
                placeholder="请输入流程标签"
                maxlength="20"
                @input="getInput($event, i)"
                @blur="flowChange($event, t, i)"
              ></el-input>
              <span class="tips" v-show="errIndex[errIndex.indexOf(i)] === i"
                >请输入流程标签</span
              >
            </el-form-item>
          </el-form>
        </div>
        <div class="forms forms-middle">
          <div class="form-title">
            <img src="../../assets/image/traceability/icon6.png" />
            <span>媒体信息</span>
          </div>
          <div class="form-item center-item">
            <div class="subtitle">图像</div>
            <div class="uploads">
              <div class="preview">
                <img
                  class="imgs"
                  v-if="isImgUploadShow"
                  :src="uploadImgs + imageUrl"
                />
                <!-- ref="uploadImg" -->

                <el-upload
                  class="upload-img"
                  :class="{ disabled: isUploadDisabled }"
                  v-if="!isImgUploadShow"
                  action="https://resource.darsing.net/api/v1/img"
                  name="img"
                  :headers="uploadHeaders"
                  :on-success="handleAvatarSuccess"
                  :on-error="handleAvatarFail"
                  :before-upload="beforeImgUpload"
                  :on-remove="removeFile"
                  :show-file-list="false"
                  :auto-upload="true"
                >
                  <img src="../../assets/image/traceability/icon9.png" />
                  <p>上传图片</p>
                </el-upload>
              </div>
              <div class="icon-buttons" v-if="isImgUploadShow">
                <el-upload
                  class="upload-img icon11"
                  action="https://resource.darsing.net/api/v1/img"
                  name="img"
                  :headers="uploadHeaders"
                  :on-success="handleAvatarSuccess"
                  :on-error="handleAvatarFail"
                  :before-upload="beforeImgUpload"
                  :on-remove="removeFile"
                  :show-file-list="false"
                  :auto-upload="true"
                >
                  <img src="../../assets/image/traceability/icon11.png" />
                </el-upload>
                <img
                  class="icon12"
                  src="../../assets/image/traceability/icon12.png"
                  @click="delImg"
                />
              </div>
            </div>
          </div>
          <div class="form-item center-item">
            <div class="subtitle">视频</div>
            <div class="uploads">
              <div class="preview">
                <video
                  class="videos"
                  :src="uploadFiles + '/' + videoShowUrl"
                  width="100%"
                  controls
                  autoplay
                  :key="menuKey"
                  v-if="isFileUploadShow"
                ></video>
                <el-upload
                  class="upload-img"
                  ref="upload"
                  :class="{ disabled: isUploadDisabled }"
                  v-if="!isFileUploadShow"
                  :action="uploadFiles"
                  name="file"
                  :show-file-list="false"
                  :auto-upload="true"
                  :headers="uploadHeaders"
                  :on-success="handleVideoSuccess"
                  :on-error="handleVideoFail"
                  :on-remove="removeVideoFile"
                  :on-change="handleVideoChange"
                  :before-upload="beforeVideoUpload"
                >
                  <img src="../../assets/image/traceability/icon9.png" />
                  <p>上传视频</p>
                </el-upload>
              </div>
              <div class="icon-buttons" v-if="isFileUploadShow">
                <el-upload
                  class="upload-img icon11"
                  ref="upload"
                  :action="uploadFiles"
                  name="file"
                  :show-file-list="false"
                  :auto-upload="true"
                  :headers="uploadHeaders"
                  :on-success="handleVideoSuccess"
                  :on-change="handleVideoChange"
                  :on-error="handleVideoFail"
                  :on-remove="removeVideoFile"
                  :before-upload="beforeVideoUpload"
                >
                  <img src="../../assets/image/traceability/icon11.png" />
                </el-upload>
                <img
                  class="icon12"
                  src="../../assets/image/traceability/icon12.png"
                  @click="delVideo"
                />
              </div>
            </div>
            <div class="video-tips">*视频大小需小于30MB</div>
          </div>
        </div>
        <div class="forms forms-right">
          <div class="form-title">
            <img src="../../assets/image/traceability/icon8.png" />
            <span>录入信息</span>
          </div>
          <el-form
            :model="enteringForm"
            ref="enteringForm"
            class="complete-form form-item"
          >
            <div class="subtitle">扫码录入</div>
            <el-form-item
              label="扫码录入"
              :label-width="formLabelWidth"
              prop="code"
              class="other-margin-bottom"
            >
              <el-input
                class="ohter-width"
                v-model.trim="enteringForm.code"
                autocomplete="off"
                placeholder="扫描要编辑的溯源码"
              ></el-input>
            </el-form-item>

            <div class="subtitle">批次选择录入</div>
            <el-form-item
              label="批次"
              :label-width="formLabelWidth"
              prop="batch"
            >
              <el-select
                class="ohter-width"
                v-model="enteringForm.batch"
                placeholder="请选择批次"
                v-loadMore="loadMoreBatchData"
              >
                <el-option
                  :label="item.title"
                  :value="item.id"
                  v-for="item in batchList"
                  :key="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <div class="dialog-footer">
              <el-button class="ok-button" type="primary" @click="okAdd"
                >确 定</el-button
              >
              <el-button class="no-button" @click="noAdd('enteringForm')"
                >重 置</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getFlowListApi,
  getProductListApi,
  getProductClassListApi,
  getPlotListApi,
  getTranceAppendApi,
  getBatchListApi,
  getFlowInfoApi,
} from "@/request/api";
import { mapState } from "vuex";
export default {
  components: {
    MyChangeMeans: () => import("@/components/MyChangeMeans.vue"),
  },
  data() {
    return {
      isFocusShow: false,
      errIndex: [],
      flowId: "",
      cropClass: "",
      cropClassId: "",
      crop: "",
      cropClassList: [],
      cropItemsList: [],
      flowList: [],
      tagArr: [],
      tagArrVal: [],
      landList: [],
      meanClassList: [],
      batchList: [],
      form: {
        type: "",
        capital: {},
        plough: "",
        work: "",
        land: "",
      },
      enteringForm: {
        code: "",
        batch: undefined,
      },
      formLabelWidth: "76px",
      isImgUploadShow: false,
      imageUrl: "",
      uploadHeaders: {
        Authorization: localStorage.getItem("darsingToken"),
      },
      isUploadDisabled: false,
      isFileUploadShow: false,
      videoUrl: "",
      videoShowUrl: "",
      menuKey: 1,
      videoFlag: false,
      videoUploadPercent: 0,
      landTotal: 10,
      landCurrent_page: 1,
      classTotal: 10,
      classCurrent_page: 1,
      productTotal: 10,
      productCurrent_page: 1,
      batchTotal: 10,
      batchCurrent_page: 1,
    };
  },
  watch: {
    crop() {
      this.batchList = [];
      this.enteringForm.batch = undefined;
    },
    cropClass() {
      this.cropItemsList = [];
    },
  },
  computed: {
    ...mapState({
      farmID: (state) => state.user.farmID,
      token: (state) => state.user.token,
      uploadImgs: (state) => state.app.uploadImgs,
      uploadFiles: (state) => state.app.uploadFiles,
    }),
  },
  created() {
    this.getProductClassListApi();
    this.getLandLists();
  },
  methods: {
    flowChange(e, t, index) {
      if (!t) {
        this.errIndex.push(index);
      } else {
        this.errIndex.splice(this.errIndex.indexOf(index), 1);
      }
    },
    getInput(e, i) {
      this.tagArrVal[i] = e;
      this.$forceUpdate();
    },
    //滚动到底部时会触发的函数
    loadMoreLandData() {
      if (this.landList.length && this.landList.length == this.landTotal)
        return;
      this.landCurrent_page++;
      this.getLandLists(this.landCurrent_page);
    },
    loadMoreClassData() {
      if (
        this.cropClassList.length &&
        this.cropClassList.length == this.classTotal
      )
        return;
      this.classCurrent_page++;
      this.getProductClassListApi(this.classCurrent_page);
    },
    loadMoreItemsData() {
      if (
        this.cropItemsList.length &&
        this.cropItemsList.length == this.productTotal
      )
        return;
      this.productCurrent_page++;
      this.changeCropClass(this.productCurrent_page, this.cropClassId);
    },
    loadMoreBatchData() {
      if (this.batchList.length && this.batchList.length == this.batchTotal)
        return;
      this.batchCurrent_page++;
      this.getBatchListApi(this.batchCurrent_page);
    },
    async getBatchListApi(pn = 1) {
      const { code, results } = await getBatchListApi({
        manor_id: this.farmID,
        product_id: this.crop,
        pn,
      });
      if (code === 0) {
        this.batchList = [...this.batchList, ...results.data];
        this.batchCurrent_page = results.pn;
        this.batchTotal = results.count;
      }
    },
    async getLandLists(pn = 1) {
      const { code, results } = await getPlotListApi({
        manor_id: this.farmID,
        pn,
      });
      if (code === 0) {
        this.landList = [...this.landList, ...results.data];
        this.landCurrent_page = results.pn;
        this.landTotal = results.count;
      }
    },
    async getFlowInfoApi() {
      const { code, results } = await getFlowInfoApi({
        flow_id: this.flowId,
      });
      if (code === 0) {
        this.tagArr = results.tag;
      }
    },
    async changeCropItems() {
      const { code, results } = await getFlowListApi({
        manor_id: this.farmID,
        product_id: this.crop,
      });
      if (code === 0) {
        this.flowList = results;
      }
      this.getBatchListApi();
    },
    changeFlow() {
      this.tagArr = [];
      this.tagArrVal = [];
      this.getFlowInfoApi();
    },
    async changeCropClass(pn = 1, id) {
      this.crop = "";
      this.flowId = "";
      this.cropClassId = id;
      const { code, results } = await getProductListApi({
        manor_id: this.farmID,
        cid: id,
        pn,
      });
      if (code === 0) {
        this.cropItemsList = [...this.cropItemsList, ...results.data];
        this.productCurrent_page = results.pn;
        this.productTotal = results.count;
        this.flowList = [];
      }
    },
    async getProductClassListApi(pn = 1) {
      const { code, results } = await getProductClassListApi({
        manor_id: this.farmID,
        pn,
      });
      if (code === 0) {
        this.cropClassList = [...this.cropClassList, ...results.data];
        this.classCurrent_page = results.pn;
        this.classTotal = results.count;
      }
    },
    getFocus() {
      this.isFocusShow = true;
    },
    getBlur() {
      this.isFocusShow = false;
    },
    getMeansList(val) {
      this.form.capital = val;
    },
    noAdd(formName) {
      this.$refs[formName].resetFields();
      this.imageUrl = "";
      this.videoUrl = "";
      this.videoShowUrl = "";
      this.crop = "";
      this.cropClass = "";
      this.flowId = "";
      this.tagArr = [];
      this.tagArrVal = [];
      this.flowList = [];
      this.form = {
        type: "",
        capital: {},
        plough: "",
        work: "",
        land: "",
      };
      this.$refs.changeMean.showMeansList = [];
      this.$refs.changeMean.meansList = {};
    },
    async okAdd() {
      if (!this.cropClassId) {
        this.$notify.error({
          title: "错误",
          message: "请选择品种分类",
        });
        return;
      } else if (!this.crop) {
        this.$notify.error({
          title: "错误",
          message: "请选择产品",
        });
        return;
      } else if (!this.flowId) {
        this.$notify.error({
          title: "错误",
          message: "请选择流程",
        });
        return;
      } else if (!this.form.land) {
        this.$notify.error({
          title: "错误",
          message: "请选择地块",
        });
        return;
      } else if (this.errIndex.length) {
        this.$notify.error({
          title: "错误",
          message: "请填写流程标签",
        });
        return;
      } else if (!this.enteringForm.batch && !this.enteringForm.code) {
        this.$notify.error({
          title: "错误",
          message: "请填写溯源码或选择批次",
        });
        return;
      }
      let data = {};
      let psn;
      this.tagArrVal.forEach((item, index) => {
        data[this.tagArr[index]] = item;
      });
      this.enteringForm.code
        ? (psn = this.enteringForm.code.match(/\d+-[a-zA-Z0-9]{8}(-\d+)?/)[0])
        : (psn = undefined);
      const { code, msg } = await getTranceAppendApi({
        flow_id: this.flowId,
        data,
        SN: psn,
        batch_id: this.enteringForm.batch,
        plot_id: this.form.land,
        resource: this.form.capital,
        photo: this.imageUrl,
        video: this.videoShowUrl,
      });
      if (code === 0) {
        this.$notify({
          title: "成功",
          message: msg,
          type: "success",
        });
        this.imageUrl = "";
        this.videoUrl = "";
        this.videoShowUrl = "";
        this.crop = "";
        this.cropClass = "";
        this.flowId = "";
        this.form = {
          type: "",
          capital: {},
          plough: "",
          work: "",
          land: "",
        };
        this.$refs.changeMean.showMeansList = [];
        this.$refs.changeMean.meansList = {};
        this.isImgUploadShow = false;
        this.isFileUploadShow = false;
      }
    },

    handleVideoSuccess(res, file) {
      if (file.percentage === 100) {
        this.videoShowUrl = file.response.Data.filename;
        this.isFileUploadShow = true;
        this.$notify({
          title: "成功",
          message: "上传成功",
          type: "success",
        });
      }
    },
    handleVideoChange(file, fileList) {
      this.videoUrl = fileList[0].raw;
    },
    beforeImgUpload(file) {
      const isImg =
        file.type === "image/png" ||
        file.type === "image/bmp" ||
        file.type === "image/gif" ||
        file.type === "image/jpg" ||
        file.type === "image/psd" ||
        file.type === "image/tif" ||
        file.type === "image/jpeg";
      if (!isImg) {
        this.$notify.error({
          title: "错误",
          message: "格式错误",
        });
      }
      return isImg;
    },
    beforeVideoUpload(file) {
      const isVideo =
        file.type === "video/mp4" ||
        file.type === "video/ogg" ||
        file.type === "video/flv" ||
        file.type === "video/avi" ||
        file.type === "video/wmv" ||
        file.type === "video/rmvb";
      const isLtMb = file.size / 1024 / 1024 < 32;
      if (!isVideo) {
        this.$notify.error({
          title: "错误",
          message: "格式错误",
        });
      }
      if (!isLtMb) {
        this.$notify.error({
          title: "错误",
          message: "文件大小不能超过30MB",
        });
      }
      return isVideo && isLtMb;
    },

    uploadVideoProcess(event, file) {
      this.videoFlag = true;
      this.videoUploadPercent = parseInt(file.percentage);
    },
    handleVideoFail() {
      this.isImgUploadShow = false;
      this.$notify.error({
        title: "错误",
        message: "上传失败，稍后尝试",
      });
    },
    delVideo() {
      this.videoUrl = "";
      this.videoShowUrl = "";
      this.isFileUploadShow = false;
    },
    removeVideoFile() {
      this.videoUrl = "";
      this.videoShowUrl = "";
      this.isFileUploadShow = false;
    },

    editImg() {},
    delImg() {
      this.removeFile();
    },
    removeFile() {
      this.imageUrl = "";
      this.isImgUploadShow = false;
    },
    handleAvatarSuccess(res, file) {
      if (file.percentage === 100) {
        this.imageUrl = file.response.Data.imgid;
        this.isImgUploadShow = true;
        this.$notify({
          title: "成功",
          message: "上传成功",
          type: "success",
        });
      }
    },
    handleAvatarFail() {
      this.isImgUploadShow = false;
      this.$notify.error({
        title: "错误",
        message: "上传失败，稍后尝试",
      });
    },
  },
};
</script>

<style lang="less" scoped>
.outDiv {
  padding: 0 48px;
  .info-input {
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #0e2138;
    padding: 29px 48px;

    .info-top {
      width: 100%;
      height: 40px;
      margin-bottom: 33px;
      display: flex;
      align-items: center;

      .per-class {
        display: flex;
        align-items: center;
        color: #fff;
        span {
          font-size: 14px;
          margin: 0 16px 0 37px;
        }

        /deep/.el-select {
          width: 136px;

          .el-input__inner {
            width: 100%;
            background-color: #081c30;
            border: 1px solid #385576;
            color: #57708d;
            font-size: 14px;
          }
        }

        /deep/.el-select.width170 {
          width: 170px;
        }
      }
    }

    .info-main {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .forms {
        width: 556px;
        height: 696px;
        background: url(../../assets/image/traceability/icon13.png) center
          center;
        background-size: cover;
        display: flex;
        flex-direction: column;

        &.forms-middle {
          background: url(../../assets/image/traceability/icon14.png) center
            center;
          .form-item {
            height: auto;
            .subtitle {
              margin-bottom: 21px;
            }
          }
        }

        &.forms-right {
          background: url(../../assets/image/traceability/icon15.png) center
            center;
        }

        .form-title {
          width: 100%;
          height: 56px;
          background: #0e2f53;
          display: flex;
          align-items: center;
          padding: 0 29px;

          img {
            width: 28px;
            height: 28px;
            margin-right: 9px;
          }

          span {
            font-size: 22px;
            color: #fff;
          }
        }

        .form-item {
          height: calc(100% - 41px);
          padding: 41px 32px;
          width: 100%;
          position: relative;
          &.center-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
          /deep/.el-form-item {
            margin-bottom: 38px;
            .ohter-width.el-select,
            .el-input {
              width: 378px;
              .el-input__inner {
                width: 100%;
                background-color: #081c30;
                border: 1px solid #385576;
                color: #57708d;
                font-size: 14px;
                &:focus {
                  border-color: #3e90e5;
                }
              }
            }
            .el-form-item__label {
              font-size: 14px;
              color: #fff;
            }
          }
          .other-margin-bottom {
            margin-bottom: 163px;
          }
          .subtitle {
            width: 100%;
            position: relative;
            font-size: 16px;
            padding: 0 15px;
            margin-bottom: 47px;
            color: #fff;

            &::before {
              content: "";
              width: 4px;
              height: 15px;
              position: absolute;
              top: 0;
              left: 0;
              background: #3e90e5;
              border-radius: 2px;
            }
          }
          .tips {
            color: #f56c6c;
          }
          .uploads {
            display: flex;
            align-items: center;
            .preview {
              width: 302px;
              height: 200px;
              background: #092138;
              border: 1px solid #57708d;
              border-radius: 3px;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              cursor: pointer;
              .upload-img {
                &.disabled {
                  .el-upload--picture-card {
                    display: none;
                  }
                }
              }

              img {
                width: 56px;
                height: 46px;
                margin-bottom: 14px;
              }
              p {
                width: 79px;
                height: 23px;
                background: rgba(62, 144, 229, 0.1);
                line-height: 23px;
                text-align: center;
                font-size: 14px;
                color: #3e90e5;
              }
              .imgs {
                width: 70%;
                height: 98%;
                margin-bottom: 0;
              }
              .videos {
                width: 100%;
                height: 100%;
              }
            }

            .icon-buttons {
              display: flex;
              flex-direction: column;
              margin-left: 37px;
              .icon11 {
                margin-bottom: 30px;
              }
              img {
                width: 32px;
                height: 32px;
                cursor: pointer;
              }
            }
          }
          .video-tips {
            width: 100%;
            color: #999;
            font-size: 10px;
            position: absolute;
            left: 32px;
            bottom: 10px;
          }
          /deep/.dialog-footer {
            width: 100%;
            display: flex;
            justify-content: space-around;
            padding: 0 10px;
            margin-top: 90px;
            .el-form-item__content {
              width: 353px;
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }

          .ok-button {
            width: 150px;
            height: 40px;
          }

          .no-button {
            width: 150px;
            height: 40px;
            border: 1px solid #385576;
            background-color: rgb(15, 36, 61);
          }
        }
        .soll-form {
          overflow-y: auto;
          &::-webkit-scrollbar {
            width: 5px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #3e90e5;
          }
        }
      }
    }
  }
}
</style>
